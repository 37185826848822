import React from "react";
interface cardProps {
  image: string;
  textHeader: string;
  subTextHeader: string;
  color: string;
}
export default function Card(props: cardProps) {
  return (
    <div className="bg-dark-accent flex flex-col p-6 rounded-[20px] shadow-default">
      <div className="flex flex-row items-center justify-center">
        <img
          src={props.image}
          alt={props.textHeader}
          className="tablet:h-[150px] scale-95"
        />
      </div>
      <div>
        <div className="flex flex-col items-center">
          <h1
            className={`mt-6 ${props.color} text-3xl   text-center whitespace-pre-line`}
          >
            {props.textHeader}
          </h1>
          <h2 className="text-lg tablet:text-xl  text-center whitespace-pre-line">
            {props.subTextHeader}
          </h2>
        </div>
      </div>
    </div>
  );
}

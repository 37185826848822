import React from "react";
import etda from "../../assets/images/partner/etda.png";
import nia from "../../assets/images/partner/nia.png";
import m from "../../assets/images/partner/m.png";
import smid from "../../assets/images/partner/smid.png";
import chiangmai from "../../assets/images/partner/chiangmai.png";
import school from "../../assets/images/partner/school.png";
import techsauce from "../../assets/images/partner/techsauce.png";
import logo from "../../assets/images/ETDALogo.png";
import bpt from "../../assets/images/partner/บพท.png";

const image = [etda, nia, m, smid, chiangmai, school, bpt, techsauce];
export default function FooterComponent() {
  const invite: boolean = false;
  return (
    <div className="flex flex-col font-['FcMinimal-normal'] text-white text-center">
      <div className="bg-white  p-8 tablet:p-3 tablet:px-8">
        <div className="grid mobile:grid-cols-4 tablet:flex flex-row justify-center gap-4 ">
          {image.map((item: string, index: number) => (
            <div
              key={`partner-${item}`}
              className={`flex items-center mobile:justify-center tablet:justify-center `}
            >
              <img src={item} alt={item} />
            </div>
          ))}
        </div>
      </div>
      {/* tablet desktop */}
      <div className="mobile:hidden tablet:flex bg-dark-accent flex-col justify-center items-center p-8 ">
        <h1 className="text-yellow-accent text-3xl font-normal ">
          ชวนมาร่วม HACK!
        </h1>
        <h2 className="text-center text-2xl font-normal">
          กับโจทย์ที่จะช่วยแก้ปัญหาให้กับชาวเชียงใหม่และคนในพื้นที่
        </h2>
        <h2 className="text-yellow-accent text-center text-2xl font-normal">
          ส่งเสริมบ้านเมืองดี สุขภาพดี อาหารดี
        </h2>
        <h2 className="text-center text-2xl font-normal">
          เพราะ WELL-BEING ไม่ใช่แค่เรื่องสุขภาพ
        </h2>

        {invite && (
          <div className="mobile:hidden tablet:block mt-8 ">
            <a
              href="https://techsauceteam.typeform.com/to/hr8OhsHd"
              className="flex items-center justify-center tablet:w-64 laptop:w-80 desktop:w-96 tablet:h-8 laptop:h-14 px-3 py-6  z-10 bg-pear-accent rounded-[40px]  font-[FcMinimal-bold]  text-2xl font-bold text-blue-primary"
            >
              สมัครเข้าร่วมโครงการ
            </a>
          </div>
        )}
        <div className="mt-8">
          <img src={logo} alt="" />
        </div>
        <h4 className="mt-4 text-2xl">
          สอบถามรายละเอียดเพิ่มเติม <br />
          โทร 02-001-5375 Email: Hackathon@techsauce.co
          {/* <br /> วันจันทร์ - ศุกร์ เวลา 9.00 - 18.00 น. */}
        </h4>
        <ul className="flex flex-row items-center w-full justify-center gap-2 laptop:gap-8 text-yellow-accent text-base laptop:text-lg mt-8">
          <li className="p-2">
            <a href="#about">เกี่ยวกับโครงการ</a>
          </li>
          <li>|</li>

          <li className="p-2">
            <a href="#challenge">โจทย์จากโครงการ</a>
          </li>
          <li>|</li>

          <li className="p-2">
            <a href="#criteria">คุณสมบัติผู้สมัคร</a>
          </li>
          <li>|</li>
          <li className="p-2">
            <a href="#benefit">สิ่งที่จะได้รับ</a>
          </li>
          <li>|</li>

          <li className="p-2">
            <a href="#process">ขั้นตอนการสมัคร</a>
          </li>
          <li>|</li>

          <li className="p-2">
            <a href="#activity">ภาพบรรยากาศ</a>
          </li>
        </ul>
      </div>
      {/* mobile */}
      <div className="flex tablet:hidden bg-blue-primary flex-col justify-center items-center p-8">
        <h1 className="text-yellow-accent text-3xl font-normal ">
          ชวนมาร่วม HACK!
        </h1>
        <h2 className="text-center text-xl font-normal">
          กับโจทย์ที่จะช่วยแก้ปัญหาให้กับชาวเชียงใหม่และคนในพื้นที่
        </h2>
        <h2 className="text-yellow-accent text-center text-xl font-normal">
          ส่งเสริมบ้านเมืองดี สุขภาพดี อาหารดี
        </h2>
        <h2 className="text-center text-xl font-normal">
          เพราะ WELL-BEING ไม่ใช่แค่เรื่องสุขภาพ
        </h2>
        <h4 className="mt-4 text-xl">
          สอบถามรายละเอียดเพิ่มเติม <br />
          โทร 02-001-5375 Email: Hackathon@techsauce.co
          {/* <br /> วันจันทร์ - ศุกร์ เวลา 9.00 - 18.00 น. */}
        </h4>
        <div className="font-['FcPalette'] text-yellow-accent text-xl mt-8 underline underline-offset-2">
          <a href="#hero">กลับขึ้นด้านบน</a>
        </div>
      </div>
    </div>
  );
}

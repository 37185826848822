import "./App.css";
import FooterComponent from "./components/footer/footer-component";
import Navbar from "./components/navbar/navbar";
import Homepage from "./page/homepage";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Homepage />
      <FooterComponent />
    </div>
  );
}

export default App;

import logo from "../../assets/images/partner/etda.png";
import hamberger from "../../assets/images/hamberger.png";
import grid from "../../assets/images/Grid.png";
import * as React from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  createTheme,
  ThemeProvider,
} from "@mui/material";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const theme = createTheme({
  typography: {
    fontFamily: "FcMinimal-bold",
  },
});
export default function Navbar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <ThemeProvider theme={theme}>
      <Box
        onClick={handleDrawerToggle}
        sx={{
          textAlign: "center",
          background: "#1454CC",
          color: "white",
          height: "100%",
        }}
      >
        <div className="flex flex-row justify-center p-4 bg-white ">
          <div className="flex flex-row items-center scale-50 h-[22px]">
            <img src={logo} alt="logo" />
          </div>
        </div>

        <List sx={{ fontFamily: "FcMinimal-bold", padding: 0 }}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} href="#activity">
              <ListItemText
                primaryTypographyProps={{ fontSize: "20px" }}
                primary={"ภาพบรรยากาศกิจกรรม"}
                sx={{ fontSize: "24px" }}
              />
            </ListItemButton>
          </ListItem>
          <Divider
            sx={{ borderColor: "rgba(255,255,255,0.3)", marginX: "0" }}
          />
        <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} href="#calendar">
              <ListItemText
                primaryTypographyProps={{ fontSize: "20px" }}
                primary={"ปฎิทินโครงการ"}
                sx={{ fontSize: "24px" }}
              />
            </ListItemButton>
          </ListItem>
          <Divider
            sx={{ borderColor: "rgba(255,255,255,0.3)", marginX: "0" }}
          />
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              // target="_blank"
              href="#annoucement"
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "20px" }}
                primary={"ประกาศรายชื่อผู้เข้ารอบ"}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </ThemeProvider>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="flex text-white font-['FcMonomal']">
      <AppBar
        sx={{
          border: "none",
          backgroundColor: "#1454CC",
          backgroundImage: grid,
        }}
        component="nav"
        position="fixed"
      >
        <Toolbar
          sx={{ paddingLeft: "0 !important", minHeight: "50px !important" }}
        >
          <div className="flex flex-row justify-between w-full h-full font-['FcMinimal-bold'] p-0">
            <div className="flex flex-row items-center bg-white p-4 rounded-tr-[20px] rounded-br-[20px] mobile:w-[120px] mobile:h-50px tablet:w-[140px] tablet:h-[60px]">
              <img src={logo} alt="logo" />
            </div>
            <ul className="hidden tablet:flex list-none justify-around items-center font-bold text-lg space-x-4">
            <li>
             <a href="#activity">ภาพบรรยากาศกิจกรรม</a>
           </li>
              {/* open on 21 Mar 2023  */}
              {/* 
              <li>
             <a href="#">ภาพบรรยากาศกิจกรรม</a>
           </li>
           <li>
             <a href="#">ประกาศผู้เข้ารอบ</a>
           </li> */}
              <li>
                <a href="#calendar">ปฎิทินโครงการ</a>
              </li>
              <li>
                <a
                  href="#annoucement"
                  // target="_blank"
                  className="flex items-center justify-center w-56 h-10  bg-pear-accent rounded-[40px]  font-[FcMinimal-bold] text-xl  font-bold text-blue-primary"
                >
                  ประกาศรายชื่อผู้เข้ารอบ
                </a>
              </li>
            </ul>
            <button className="tablet:hidden" onClick={handleDrawerToggle}>
              <img src={hamberger} alt="button"></img>
            </button>
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRadius: "0 35px 35px 0",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
}

// import Swiper core and required modules
import { Navigation, Pagination, Thumbs, EffectFade, Autoplay } from "swiper";
import { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "./swiper-component.css";
import { shuffleArray } from "../../common/utils/arrayUtils";
import { atmospheres } from "../../page/atmospheres";
// import IMG_2066 from "../../assets/images/atmospheres/IMG_2066.jpg"
export default function SwiperComponent() {
  // const slides = atmospheres.map(

  //   (el, index) => `Slide ${index + 1}`
  // );
  // console.log( slides)
  return (
    <Swiper
      modules={[Virtual, Navigation, Pagination, Thumbs, EffectFade, Autoplay]}
      spaceBetween={10}
      slidesPerView={3}
      virtual
      autoplay={{ delay: 5000, disableOnInteraction: false }}
      loop={true}
      className="relative overflow-hidden rounded-3xl "
      navigation
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      breakpoints={{
        320: { slidesPerView: 1 },

        768: { slidesPerView: 2 },
        1024: { slidesPerView: 3 },
      }}
    >
      {shuffleArray(atmospheres).map((slideContent, index) => (
        <SwiperSlide
          key={slideContent}
          virtualIndex={index}
          className=" text-blue-primary rounded-3xl "
        >
          <div className="flex flex-col justify-center items-center h-fit">
            <img src={slideContent} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export const atmospheres = [
    "./images/atmospheres/001-min-min.png",
    "./images/atmospheres/002-min-min.png",
    "./images/atmospheres/003-min-min.png",
    "./images/atmospheres/004-min-min.png",
    "./images/atmospheres/005-min-min.png",
    "./images/atmospheres/006-min-min.png",
    "./images/atmospheres/007-min-min.png",
    "./images/atmospheres/008-min-min.png",
    "./images/atmospheres/009-min-min.png",
    "./images/atmospheres/010-min-min.png"
];
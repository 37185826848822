import { useState } from "react";
import SwiperComponent from "../components/swiper/swiper-component";

import closeInvite from "../assets/images/closeInvite.png";
import openInvite from "../assets/images/openInvite.png";
import hero from "../assets/images/Hero.png";
import about from "../assets/images/about.png";
import vector from "../assets/images/vector.png";
// import partner from "../assets/images/partner.png";
import arrowDown from "../assets/images/arrow.png";
import orangeArrow from "../assets/images/orange-arrow.png";
import traced from "../assets/images/traced.png";
import tracedYellow from "../assets/images/traced-yellow.png";
import environment from "../assets/images/environment.png";
import family from "../assets/images/family.png";
import healthy from "../assets/images/healthy.png";
import criteria from "../assets/images/criteria.png";
import downArrow from "../assets/images/down-arrow.png";
import benefit from "../assets/images/benefit.png";
import people from "../assets/images/people-alt.png";
import goal from "../assets/images/goal.png";
import network from "../assets/images/network.png";
import writer from "../assets/images/writer.png";
import Card from "../components/card/card";
import etda from "../assets/images/partner/etda.png";
import nia from "../assets/images/partner/nia.png";
import m from "../assets/images/partner/m.png";
import smid from "../assets/images/partner/smid.png";
import chiangmai from "../assets/images/partner/chiangmai.png";
import school from "../assets/images/partner/school.png";
import techsauce from "../assets/images/partner/techsauce.png";
import logo from "../assets/images/ETDALogo.png";
import bpt from "../assets/images/partner/บพท.png";
import "./styles/homepage.css";
interface ChallengeObject {
  image: string;
  textHeader: string;
  subTextHeader: string;
  color: string;
}
interface CalendarObject {
  month: string;
  content: { topic: string; detail: string }[];
}
interface InviteButtonProps {
  open: boolean;
}
//config
const invite = false;
const openSection = {
  hero: true,
  intoduction: true,
  about: true,
  announcement: true,
  activity: true,
  challenge: true,
  criteria: true,
  process: true,
  benefit: true,
  calendar: true,
};

function InviteButton(props: InviteButtonProps) {
  return (
    <>
      {props.open && (
        <a
          href="https://techsauceteam.typeform.com/to/hr8OhsHd"
          target="_blank"
          className="flex flex-row items-center justify-center bg-pear-accent text-blue-primary font-['FcMinimal-bold'] rounded-[30px] mobile:text-2xl laptop:text-3xl h-12 min-w-[14rem] laptop:h-14 py-4 px-8"
        >
          สมัครเข้าร่วมโครงการ
        </a>
      )}
    </>
  );
}
function AboutContent() {
  const [showFullText, setShowFullText] = useState(false);
  const fullText = `จากการที่ ETDA หรือ สํานักงานพัฒนาธุรกรรมทางอิเล็กทรอนิกส์ (สพธอ.)มีหน้าที่ส่งเสริมและสนับสนุนหน่วยงานที่เกี่ยวข้องเพื่อพัฒนาระบบนิเวศในการทําธุรกรรมทางอิเล็กทรอนิกส์ ช่วยให้องค์กรนำเทคโนโลยีมาใช้ในการทำงาน ตลอดจนผลักดันให้เกิดธุรกิจและบริการด้านดิจิทัล และส่งเสริมให้เกิดการรับเทคโนโลยีดิจิทัลมาใช้สร้างมูลค่าทางเศรษฐกิจได้ \n  
    ETDA จึงทำงานร่วมกับหลายหน่วยงาน เพื่อส่งเสริมและสนับสนุนการประยุกต์ใช้นวัตกรรมและเทคโนโลยีใหม่ ๆ ให้เกิดการให้ความรู้ การพัฒนานวัตกรรมและบริการ ที่สามารถนำไปแก้ปัญหาได้ ผ่านการคิดวิเคราะห์เชิงลึกของปัญหารวมถึงมีการทดลองใช้กับกลุ่มเป้าหมาย \n 
    นอกจากนั้น ด้วยความร่วมมือกับ สํานักงานนวัตกรรมแห่งชาติ (องค์การมหาชน) หรือ NIA องค์กรที่เสริมสร้างระบบนวัตกรรมของชาติ ที่มีภารกิจสร้างโอกาสในการเข้าถึง และใช้ประโยชน์โครงสร้างพื้นฐานนวัตกรรม ตลอดจนยกระดับทักษะและความสามารถทางนวัตกรรมของประเทศ จึงตั้งใจร่วมมือกันเพื่อส่งเสริมการทําธุรกรรมทางอิเล็กทรอนิกส์ของผู้ให้บริการนวัตกรรม (Service Provider) ผู้ประกอบการวิสาหกิจขนาดกลางและขนาดย่อม (SMEs) ผู้ประกอบการรายย่อย (MSMEs) ให้เกิดการสร้างระบบนิเวศด้านธุรกรรมทางอิเล็กทรอนิกส์ และให้เกิดการรวมตัวกันของผู้ประกอบการ นักลงทุน ภาคเอกชน รวมถึงภาครัฐด้วย`;

  const shortText =
    "จากการที่ ETDA หรือ สํานักงานพัฒนาธุรกรรมทางอิเล็กทรอนิกส์ (สพธอ.)มีหน้าที่ส่งเสริมและสนับสนุนหน่วยงานที่เกี่ยวข้องเพื่อพัฒนาระบบนิเวศในการทําธุรกรรมทางอิเล็กทรอนิกส์ ช่วยให้องค์กรนำเทคโนโลยีมาใช้ในการทำงาน ตลอดจนผลักดันให้เกิดธุรกิจและบริการด้านดิจิทัล และส่งเสริมให้เกิดการรับเทคโนโลยีดิจิทัลมาใช้สร้างมูลค่าทางเศรษฐกิจได้ ";

  return (
    <>
      <div
        className={`transition duration-500 ease-in-out tablet:hidden  text-base whitespace-pre-line`}
      >
        <p>{showFullText ? fullText : shortText}</p>
      </div>
      <div
        className={`hidden tablet:block  text-xl font-[FcMinimal-normal] leading-[30px] whitespace-pre-line`}
      >
        <p>{fullText}</p>
      </div>
      <button
        className="flex flex-row justify-center items-center gap-2 tablet:hidden mt-4 bg-white/[0.4] backdrop-blur-[20px] hover:bg-blue-600 text-pear-accent transition-colors font-[FcMinimal-normal] font-bold py-2 px-4 rounded-[40px] text-xl"
        onClick={() => setShowFullText(!showFullText)}
      >
        อ่านทั้งหมด
        <img
          src={arrowDown}
          alt="arrow down"
          className={`transition duration-500 ease-in-out ${
            showFullText ? "rotate-180" : "rotate-0"
          } `}
        ></img>
      </button>
    </>
  );
}
function Annoucement() {
  const team: string[] = [
    "Smile Migraine",
    "Dietz Telemedicine Statio",
    "We Chef Food Truck Platform",
    "Talk to PEACH",
    "Kaset Market (เกษตร มาร์เก็ต)",
  ];
  return (
    <>
      {team.map((name: string, index: number) => {
        return (
          <div
            key={name + index}
            className={`${
              team.length - 1 === index && team.length % 2 === 1
                ? "tablet:col-span-2 tablet:items-center tablet:mx-auto tablet:w-1/2"
                : "tablet:col-span-2 tablet:items-center tablet:mx-auto tablet:w-1/2"
            } flex flex-row justify-start items-center px-4 py-3  rounded-[15px] bg-[#F2F2F2] text-blue-primary mobile:text-xl tablet:text-xl font-normal shadow-default team min-h-[10px]`}
          >
            {name}
          </div>
        );
      })}
    </>
  );
}

function Challenge() {
  const data: ChallengeObject[] = [
    {
      image: environment,
      textHeader: "Good Home\nบ้าน (เมือง) สะอาดดี ",
      subTextHeader:
        "นวัตกรรมเปลี่ยนเมืองเชียงใหม่ ด้วยการยกระดับมาตรฐานด้านสุขอนามัยและความสะอาดของพื้นที่\n\nปัญหาที่พบ:\nความไม่สะอาดของพื้นที่สาธารณะ รวมถึงสุขอนามัยของแหล่งชุมชน เช่น ตลาด ถนน จุดเสื่อมโทรมต่างๆ เป็นต้น",
      color: "text-green-secondary",
    },
    {
      image: healthy,
      textHeader: "Good Healthcare\nบริการสุขภาพดี",
      subTextHeader:
        "นวัตกรรมยกระดับบริการสุขภาพ เพื่อให้คนเชียงใหม่เข้าถึงบริการสุขภาพได้อย่างสะดวกและรวดเร็วขึ้น โดยที่ไม่จำเป็นต้องเสี่ยงต่อการสัมผัสโรค Covid-19\n\nปัญหาที่พบ: \nพบปัญหาในการรับบริการสุขภาพซึ่งผู้รับบริการต้องใช้เวลานาน เพื่อรอรับการบริการ และมีขั้นตอนซับซ้อน",
      color: "text-pear-accent",
    },
    {
      image: family,
      textHeader: "Good Food for Health \nอาหารการกินดี",
      subTextHeader:
        "นวัตกรรมที่จะช่วยให้อาหารการกินในเมืองเชียงใหม่ได้รับการส่งเสริมให้เป็นไปในแนวทาง เพื่อสุขภาพมากขึ้น ตั้งแต่ต้นทางวัตถุดิบปลอดสาร ไปจนถึงความหลากหลายของอาหารที่จะทำให้สุขภาพผู้บริโภคดีขึ้น\n\nปัญหาที่พบ: \nอาหารและสินค้าในตลาดยังปนเปื้อนสารเคมีหรือสารตกค้างจำนวนมาก",
      color: "text-skyBlue-secondary",
    },
  ];
  return (
    <>
      {data.map((item: ChallengeObject, index: number) => (
        <Card
          key={item.textHeader + index}
          image={item.image}
          color={item.color}
          textHeader={item.textHeader}
          subTextHeader={item.subTextHeader}
        />
      ))}
    </>
  );
}

function StepProcess() {
  const step: string[] = [
    "ลงทะเบียนเข้าร่วมโครงการผ่านแบบฟอร์มการสมัครออนไลน์ \nตั้งแต่วันที่ 21 ก.พ. - 15 มี.ค. 2566",
    "กรอกข้อมูลให้ครบถ้วน และตรวจสอบข้อมูลทั้งหมดให้เรียบร้อย",
    "รอผลการคัดเลือกจากคณะกรรมการ \nเพื่อเข้าสู่รอบ 40 ทีม ",
    "รอประกาศผล 15 ทีมที่ผ่านเข้าร่วมโครงการ Hack for GOOD Well-Being Creation \nนวัตกรรมดี เมืองดี ชีวิตดี",
  ];

  return (
    <>
      {step.map((item: string, index: number) => (
        <div
          key={index}
          className="p-8 bg-white/[0.04] backdrop-blur-[20px] text-pear-accent shadow-default  border-2  border-dashed border-skyBlue-dot border-spacing-28 rounded-3xl mobile:max-w-[350px] tablet:max-w-[279px] mobile:h-[280px] tablet:h-[300px]"
        >
          <h1 className="text-7xl tablet:text-8xl text-center font-['FcMinimal-bold']">
            {index + 1}
          </h1>
          <p className="mt-4  text-xl leading-[30px] tracking-[2%] text-center font-normal whitespace-pre-line">
            {item}
          </p>
          {/* {index === 0 ? (
            <a
              href="https://techsauceteam.typeform.com/to/hr8OhsHd"
              target="_blank"
              className="flex flex-row items-center justify-center bg-pear-accent text-blue-primary font-['FcMinimal-bold'] rounded-[30px] text-xl h-10 mt-2 "
            >
              สมัครเข้าร่วมโครงการ
            </a>
          ) : (
            <></>
          )} */}
        </div>
      ))}
    </>
  );
}

function Calendar() {
  const timeline: CalendarObject[] = [
    {
      month: "21 ก.พ. - 15 มี.ค. 2566",
      content: [
        {
          topic: "ประชาสัมพันธ์และเปิดรับสมัคร",
          detail:
            "ประชาสัมพันธ์และเปิดรับสมัครออนไลน์ ผ่านช่องทาง Facebook Page: ETDA Thailand และ เว็บไซต์ของโครงการ",
        },
      ],
    },
    {
      month: "23 มี.ค. 2566",
      content: [
        {
          topic: "ประกาศ 15 ทีม ผู้ผ่านเข้ารอบ",
          detail:
            "ประกาศรายชื่อ 15 ทีม ผู้ผ่านเข้ารอบ ผ่านช่องทาง Facebook Page: ETDA Thailand",
        },
      ],
    },
    {
      month: "28 มี.ค. 2566",
      content: [
        {
          topic: "กิจกรรม Open House",
          detail:
            "เปิดบ้านต้อนรับ 15 ทีมสู่โครงการ พร้อมชี้แจงกำหนดการแต่ละกิจกรรม\nแนะนำ Mentors และรายละเอียด โจทย์ของโครงการ (On-site)",
        },
      ],
    },
    {
      month: "20 เม.ย. 2566",
      content: [
        {
          topic: "กิจกรรม Workshop",
          detail:
            "กิจกรรม Workshop 2 และ 3 ผ่านช่องทางออนไลน์ กับผู้เชี่ยวชาญมากประสบการณ์ในการบ่มเพาะ Startup",
        },
      ],
    },
    {
      month: "21-22 เม.ย. 2566",
      content: [
        {
          topic: "Mentoring",
          detail:
            "พบกับ Mentoring กว่า 11 ท่าน ผู้คร่ำหวอดในวงการพัฒนา Startup หลากหลาย ความเชี่ยวชาญ แบบ 1:1 ผ่านช่องทางออนไลน์",
        },
      ],
    },
    {
      month: "27 เม.ย. 2566",
      content: [
        {
          topic: "Welcome and Networking Night",
          detail: "ต้อนรับทั้ง 15 ทีมและกิจกรรมเชื่อมโยงเครือข่าย",
        },
      ],
    },
    {
      month: "28 เม.ย. 2566",
      content: [
        {
          topic: "Hack a Day",
          detail:
            "กิจกรรม Hackathon และ Workshop 4 เตรียมความพร้อมก่อนวันแข่งขันจริง",
        },
      ],
    },
    {
      month: "29 เม.ย. 2566",
      content: [
        {
          topic: "Pitching Day",
          detail: "กิจกรรม Pitching  รอบชิงชนะเลิศ พร้อมประกาศผลการแข่งขัน",
        },
      ],
    },
  ];
  return (
    <>
      {timeline.map((item, index) => (
        <div
          key={item.month + index}
          className="grid grid-cols-6 mobile:gap-1 tablet:gap-0 py-4 border-b border-white text-xl"
        >
          <div className="col-span-2">
            <h1>{item.month}</h1>
          </div>
          <div className="col-span-4">
            <div className="flex flex-col items-start">
              {item.content.map((item) => (
                <>
                  <h4 className="text-pear-accent">{item.topic}</h4>
                  <p className="whitespace-pre-line">
                    {"\u25CF\t" + item.detail}
                  </p>
                </>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default function Homepage() {
  const imagePartner = [etda, nia, m, smid, chiangmai, school, bpt, techsauce];

  return (
    <div className="mt-16 desktop:mt-32">
      {/* section hero */}
      {openSection.hero ? (
        <section
          id="hero"
          className="flex flex-row justify-center mobile:pb-10 tablet:pb-0 desktop-4k::pb-14  bg-gradient-blue-primary "
        >
          <div
            id="desktop"
            className="hidden tablet:flex flex-row justify-center w-4/5"
          >
            <div className="grid grid-cols-12 ">
              <div className="col-span-7">
                <div className="flex flex-col justify-center items-center pt-6">
                  <div>
                    <img
                      src={hero}
                      alt="hero"
                      className="scale-[0.85] desktop:scale-[0.80]"
                    />
                  </div>
                  <div className="-mt-28 laptop:-mt-32 min-[1200px]:-mt-36 desktop:-mt-40 desktop-m:-mt-44 desktop-4k:-mt-48 z-20 min-w-[17rem] laptop:min-w-[20rem] desktop-4k:min-w-[25rem]">
                    <InviteButton open={invite} />
                  </div>
                </div>
              </div>
              <div className="col-span-5">
                <div className=" flex items-center justify-center">
                  <div>
                    {invite ? (
                      <img
                        src={openInvite}
                        alt="openInvite"
                        className="scale-[0.75] desktop-m:scale-[0.90]"
                      />
                    ) : (
                      <img
                        src={closeInvite}
                        alt="closeInvite"
                        className="scale-[0.75] desktop-m:scale-[0.90]"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="mobile"
            className="w-4/5 flex tablet:hidden flex-col justify-center items-center"
          >
            <div className="scale-100">
              <img src={hero} alt="hero" className="scale-[0.85]" />
            </div>
            <div className="font-['FcPalette'] text-white -mt-12">
              {invite ? (
                <div className="flex flex-col justify-center items-center">
                  <h1 className="text-3xl">เปิดรับสมัคร</h1>
                  <h2 className="text-lg">
                    ตั้งแต่วันที่ 21 กุมภาพันธ์ - 15 มีนาคม 2566
                  </h2>
                </div>
              ) : (
                <h1 className="text-4xl">"ปิดรับสมัคร"</h1>
              )}
            </div>
            <div className="mt-6 w-full">
              <InviteButton open={invite} />
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      {/* section introduction */}

      {openSection.intoduction ? (
        <section
          id="introduction"
          className="flex flex-col items-center tablet:pb-14 text-white font-[FcMinimal-normal] bg-gradient-radial from-blue-primary/80  to-blue-primary "
        >
          <div className="w-4/5 max-w-[1280px]  text-center font-normal leading-[30px] mobile:text-xl tablet:text-2xl tracking-wide">
            <p>
              มาร่วมกันเนรมิตเมืองเชียงใหม่ให้น่าอยู่ด้วยเทคโนโลยี ! กับโครงการ
              Hack for GOOD Well-Being Creation นวัตกรรมดี เมืองดี ชีวิตดี
              ด้วยความตั้งใจของสำนักงานพัฒนาธุรกรรมทางอิเล็กทรอนิกส์ (สพธอ.)
              หรือ ETDA ที่ต้องการผลักดัน Digital Transformation
              และพัฒนาระบบนิเวศทางธุรกรรมอิเล็กทรอนิกส์ จึงได้จัดโครงการนี้ขึ้น
              เพื่อส่งเสริมการสร้างนวัตกรรมและเทคโนโลยีมาพัฒนาเมืองและช่วยให้ชีวิตคนเชียงใหม่
              รวมถึงผู้มาเยือนมีคุณภาพชีวิตที่ดีขึ้น
              จึงขอเชิญทุกท่านมาร่วมกันแก้ปัญหา
              พัฒนาเมืองเชียงใหม่ภายใต้คอนเซปต์ ‘Hack for GOOD Well-Being
              Creation นวัตกรรมดี เมืองดี ชีวิตดี’
            </p>
            <h4 className="text-pear-accent my-10 font-bold mobile:text-2xl tablet:text-2xl laptop:text-3xl  tracking-tight desktop:tracking-wider">
              พัฒนาเมืองและส่งเสริมการเป็นอยู่ของคนเชียงใหม่ตลอดจนนักท่องเที่ยว
              <br className="mobile:hidden desktop:block" />
              ผู้มาเยือนเชียงใหม่ให้มีคุณภาพชีวิตที่ดียิ่งขึ้น โดยมีกิจกรรมหลัก
              ที่มุ่งเน้นการพัฒนา
            </h4>
          </div>
        </section>
      ) : (
        <></>
      )}

      {/* section about */}

      {openSection.about ? (
        <section
          id="about"
          className="flex flex-row justify-center bg-gradient-radial-section-about tablet:pb-14 text-white font-[FcMinimal-normal]  "
        >
          <div className="tablet:w-4/5 grid mobile:grid-rows-1 tablet:grid-cols-5 max-w-[1280px] gap-8   bg-gradient-radial from-blue-primary/40  to-blue-primary">
            <div className="mobile:mt-8 min-[400px]:mt-20 tablet:mt-0 tablet:col-span-2 ">
              <div className="flex flex-col justify-center mobile:items-center tablet:items-end mobile:h-44 tablet:h-full ">
                <div className="tablet:h-[65%] mobile:w-[65%] tablet:w-[75%]  flex flex-col items-center justify-center">
                  <img
                    src={about}
                    alt="about"
                    className="mobile:scale-75 tablet:scale-100"
                  />
                </div>
              </div>
            </div>

            <div className="mobile:mt-8 min-[400px]:mt-20 tablet:mt-0 tablet:col-span-3 tablet:col-start-3">
              <div className="flex flex-col  mobile:items-center tablet:items-start justify-center">
                <div className="flex justify-start w-4/5 ">
                  <h2 className="mobile:text-2xl tablet:text-3xl text-pear-accent tracking-wide font-normal">
                    เกี่ยวกับโครงการ
                  </h2>
                  <img
                    src={vector}
                    alt="vector"
                    className="mobile:scale-75 tablet:scale-90"
                  ></img>
                </div>
                <div className="flex w-4/5 tablet:w-full flex-col tablet:mt-4">
                  <AboutContent />
                  <div className="my-8 px-4 w-full bg-white rounded-[30px]">
                    <div className="hidden tablet:flex flex-row justify-center gap-4 ">
                      {imagePartner.map((item: string, index: number) => (
                        <div
                          key={`partner-${item}`}
                          className={`flex items-center justify-center  `}
                        >
                          <img src={item} alt={item} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      {/* section announcement */}

      {openSection.announcement ? (
        <section
          id="annoucement"
          className="flex flex-row justify-center  bg-gradient-radial mobile:from-blue-primary/50 tablet:from-blue-primary/60 mobile:to-blue-primary  tablet:to-blue-primary pb-10 tablet:pb-14 text-white font-[FcMinimal-normal]  "
        >
          <div className="flex flex-col  items-center w-full bg-gradient-to-t  from-blue-primary">
            <div className="mobile:w-[90%] tablet:w-[60%] max-w-[1280px]  flex flex-col items-center ">
              <h1 className="text-pear-accent mobile:text-2xl tablet:text-3xl mobile:w-[20rem] tablet:w-auto text-center">
                ประกาศรายชื่อผู้ชนะการแข่งขัน
              </h1>
              <div className="grid mobile:grid-flow-row tablet:grid-cols-2  mobile:gap-5 tablet:gap-7 w-full mt-10 ">
                <Annoucement />
              </div>
              <div className="flex flex-row justify-center items-center gap-4 mt-8 font-bold mobile:text-[18px] tablet:text-[22px]  laptop:text-[26px]  desktop-m:text-[32px] text-yellow-accent">
                <a href="https://www.etda.or.th/th/pr-news/Hack_for_GOOD_pitch.aspx">
                  ดูข้อมูลเพิ่มเติม{" "}
                </a>
                <img
                  src={orangeArrow}
                  alt="arrow"
                  className="w-[20px] h-[20px]"
                />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      {/* section activity */}

      {openSection.activity ? (
        <section
          id="activity"
          className="flex flex-row justify-center  bg-gradient-radial-section-activity  pb-10 tablet:pb-14 text-white font-[FcMinimal-normal]"
        >
          <div className="w-4/5 desktop:flex desktop:flex-col desktop:items-center">
            <div className="flex flex-col items-center w-full">
              <h1 className="text-pear-accent mobile:text-2xl tablet:text-3xl">
                ภาพบรรยากาศกิจกรรม
              </h1>
              <img src={traced} alt="traced-line" className="scale-75" />
            </div>

            <div className="desktop:px-20 desktop:max-w-[1280px]">
              <SwiperComponent />
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      {/* section challenge */}

      {openSection.challenge ? (
        <section
          id="challenge"
          className="flex flex-row justify-center  bg-gradient-radial mobile:from-blue-primary/50 tablet:from-blue-primary/60 mobile:to-blue-primary  tablet:to-blue-primary pb-10 tablet:pb-14 text-white font-[FcMinimal-normal]"
        >
          <div className="w-4/5 flex flex-col items-center ">
            <div className=" p-8 sparkle mobile:w-[20rem] tablet:w-96">
              <h1 className="text-pear-accent mobile:text-2xl tablet:text-3xl text-center ">
                โจทย์ท้าทายจากทางโครงการ
              </h1>
            </div>
            {/* <h2 className="-mt-4 mobile:text-xl tablet:text-2xl font-normal text-center">
              ไอเดียที่สามารถเข้าร่วมโครงการนี้ได้ต้องมีจุดมุ่งหมายที่จะแก้ปัญหาให้กับเมืองเชียงใหม่อย่างยั่งยืน
            </h2> */}
            <div className="grid mobile:grid-cols-1 laptop:grid-cols-3 min-[450px]:w-96 laptop:w-auto gap-8 mt-8 min-h-[500px] desktop:max-w-[1280px]">
              <Challenge />
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      {/* section criteria */}

      {openSection.criteria ? (
        <section
          id="criteria"
          className="flex flex-row justify-center  bg-gradient-radial mobile:from-blue-primary/50 tablet:from-blue-primary/70 mobile:to-blue-primary  tablet:to-blue-primary pb-10 tablet:pb-14 text-white font-[FcMinimal-normal]"
        >
          <div className="mobile:w-4/5 tablet:w-auto grid mobile:grid-cols-1 tablet:grid-cols-4 tablet:gap-8  tablet:px-20 desktop:max-w-[1280px]">
            <div className="  tablet:col-span-2">
              <div className="flex flex-col justify-center items-center  tablet:h-full">
                <div className=" mobile:w-3/4 tablet:h-[80%] tablet:w-[80%] flex flex-col items-center justify-center">
                  <img
                    src={criteria}
                    alt="criteria"
                    className=" tablet:scale-100 "
                  />
                </div>
              </div>
            </div>
            <div className="tablet:col-span-2 mobile:mt-10 tablet:mt-0 ">
              <div className="flex flex-row justify-center w-full desktop-m:block">
                <ul className="list-disc text-xl desktop-m:text-2xl">
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    มีสังกัดหน่วยงานที่ชัดเจน เป็นนิติบุคคลไทย (ไม่บังคับ)
                  </li>
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    พักอาศัยอยู่ประเทศไทยในระหว่างโครงการ
                  </li>
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    สามารถเข้าร่วมกิจกรรมได้ตลอดโครงการ
                  </li>
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    สมัครรูปแบบทีม 3-5 ท่าน โดย 1 ท่านมีชื่อเพียงทีม
                    เดียวเท่านั้น
                  </li>
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    หนึ่งในสมาชิกทีมมีพื้นฐานด้านการพัฒนา Software (ไม่บังคับ)
                  </li>
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    นวัตกรรมที่สมัครเข้าร่วมโครงการ ต้องเป็นนวัตกรรมใหม่, <br />
                    ต่อยอดจากนวัตกรรมเดิม หรือเป็นผู้คิดค้นนวัตกรรมด้วยตนเอง
                  </li>
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    นวัตกรรมที่นําเสนอสอดคล้องกับโจทย์ที่ประกาศในโครงการ
                  </li>
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    นวัตกรรมที่นําเสนอหรือองค์ประกอบ (ภาพ,เสียง,
                    เนื้อหา)จะต้องเป็นผลงานที่ไม่
                    <br />
                    ละเมิดลิขสิทธิ์และทรัพย์สินทางปัญญาของบุคคลอื่น
                  </li>
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    สามารถนําเสนอผลงานเป็นภาษาไทยหรือภาษาอังกฤษได้
                  </li>
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    ยินดียอมรับผลการตัดสิน รวมถึงหลักเกณฑ์ เงื่อนไข
                    ในการตัดสินทุกประการ
                  </li>
                  <li className="mobile:ml-4 laptop:mb-3 desktop-m:mb-5">
                    ผู้ผ่านเข้ารอบ 15 ทีมสุดท้าย จะต้องสามารถเข้าร่วมงาน Open
                    House (On-site)
                    <br /> ณ กรุงเทพมหานครได้และจะต้องสามารถเข้าร่วมนำเสนอผลงาน
                    (Pitching day) ณ จังหวัดเชียงใหม่ได้
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
      {/* section application process */}

      {openSection.process ? (
        <section
          id="process"
          className="flex flex-row justify-center  bg-gradient-radial mobile:from-blue-primary/50 tablet:from-blue-primary/80 mobile:to-blue-primary  tablet:to-blue-primary pb-10 tablet:pb-14 text-white font-[FcMinimal-normal] relative"
        >
          <div className="w-4/5 flex flex-col items-center tablet:mt-8">
            <div className="flex flex-row justify-center">
              <img src={downArrow} alt="" />
            </div>
            <div className="p-8 oval mobile:w-[20rem]  tablet:w-96">
              <h1 className="text-pear-accent mobile:text-2xl tablet:text-3xl text-center">
                ขั้นตอนการสมัคร
              </h1>
            </div>
            <div className="flex flex-col items-center relative">
              <div className="grid mobile:grid-cols-1 tablet:grid-cols-2  gap-9 mt-8 tablet:px-10">
                <StepProcess />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      {/* section benefit */}

      {openSection.benefit ? (
        <section
          id="benefit"
          className="flex flex-row justify-center  bg-gradient-radial mobile:from-blue-primary/50 tablet:from-blue-primary/70 mobile:to-blue-primary  tablet:to-blue-primary tablet:bg-blue-primary pb-10 tablet:pb-14 text-white font-[FcMinimal-normal]"
        >
          <div className="mobile:w-4/5 tablet:w-auto grid mobile:grid-cols-1 tablet:grid-cols-10 tablet:gap-4  tablet:px-24 desktop:max-w-[1280px]">
            <div className="mobile:col-span-1 tablet:col-span-6">
              <div className="flex flex-col justify-center mobile:items-center  ">
                <div className="flex flex-col items-center justify-center tablet:w-full">
                  <div className="flex flex-row justify-center">
                    <div className="sparkle-double">
                      <h1 className="text-pear-accent mobile:text-2xl tablet:text-3xl desktop-4k:text-4xl text-center w-80 p-8">
                        ประโยชน์ที่จะได้รับ
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex flex-col tablet:flex-row justify-center items-center gap-5 flex-wrap mobile:-mt-5 tablet:mt-8">
                      <div className="flex flex-col items-center justify-center min-h-[120px]">
                        <img src={network} alt="network" />
                        <h1 className="text-2xl text-cneter desktop-4k:text-3xl text-yellow-accent">
                          ขยายเครือข่าย
                        </h1>
                      </div>
                      <div className="flex flex-col items-center justify-center min-h-[120px]">
                        <img src={goal} alt="goal" />
                        <h1 className="text-2xl text-center desktop-4k:text-3xl text-yellow-accent">
                          เพิ่มโอกาสทางธุรกิจ
                        </h1>
                      </div>
                      <div className="flex flex-col items-center justify-center min-h-[120px]">
                        <img src={people} alt="people" />
                        <h1 className="text-2xl text-center desktop-4k:text-3xl text-yellow-accent">
                          พบผู้เชี่ยวชาญ <br />
                          หลากหลายด้าน
                        </h1>
                      </div>
                    </div>
                    <div className=" mobile:mt-8 tablet:mt-4 mobile:w-4/5 tablet:w-auto  text-xl desktop-m:text-2xl ">
                      <ul className="list-disc ">
                        <li>
                          รางวัลชนะเลิศอันดับ 1: เงินรางวัล 400,000 บาท
                          พร้อมโล่รางวัล และ ประกาศนียบัตร และ เข้าร่วม Pitching
                          ที่เวที Tech Showcase ในงาน Techsauce Global Summit
                          2023
                        </li>
                        <li>
                          รางวัลรองชนะเลิศอันดับ2: เงินรางวัล 300,000 บาท
                          พร้อมโล่รางวัล และ ประกาศนียบัตร และ เข้าร่วม Pitching
                          ที่เวที Tech Showcase ในงาน Techsauce Global Summit
                          2023
                        </li>
                        <li>
                          รางวัลรองชนะเลิศอันดับ3: เงินรางวัล 150,000 บาท
                          พร้อมโล่รางวัล และ ประกาศนียบัตร และ เข้าร่วม Pitching
                          ที่เวที Tech Showcase ในงาน Techsauce Global Summit
                          2023
                        </li>
                        <li>
                          รางวัลขวัญใจกรรมการ 2 รางวัล รางวัลละ 75,000 บาท
                          พร้อมประกาศนียบัตร
                        </li>
                        <li>
                          ทีมผู้ชนะทั้ง 5 ทีมจะได้รับ AWS Credit
                          พร้อมทั้งโอกาสในการทดสอบนวัตกรรมกับกลุ่มเป้าหมายในพื้นที่และการสนับสนุนต่อ
                          ยอดจาก Partner อื่น
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile:hidden tablet:block tablet:col-span-4 ">
              <div className="flex flex-row justify-center items-center w-full h-full">
                <img src={benefit} alt="benefit" className="scale-75" />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      {/* section calendar */}

      {openSection.calendar ? (
        <section
          id="calendar"
          className="flex flex-row justify-center mobile:bg-blue-primary tablet:bg-transparent bg-gradient-radial mobile:from-blue-primary/50 tablet:from-blue-primary/60 mobile:to-blue-primary  tablet:to-blue-primary pb-10 tablet:pb-14 text-white font-[FcMinimal-normal]"
        >
          <div className="w-4/5 max-w-[1280px] grid mobile:grid-cols-1 tablet:grid-cols-10 gap-8">
            <div className="tablet:col-span-4">
              <div className="mobile:hidden tablet:flex flex-col justify-start items-end ">
                <div className="h-[55%]">
                  <img src={writer} alt="writer" className="h-full scale-90" />
                </div>
              </div>
            </div>
            <div className="tablet:col-span-6">
              <div className="flex flex-col items-start">
                <div className="flex flex-col">
                  <h1 className="text-pear-accent mobile:text-2xl tablet:text-3xl desktop-4k:text-4xl">
                    ปฏิทินโครงการ
                  </h1>
                  <div>
                    <img src={tracedYellow} alt="" />
                  </div>
                </div>
                <div className="tablet:hidden flex flex-col items-center justify-center -mt-8 -mb-20">
                  <div>
                    <img src={writer} alt="" className="scale-75" />
                  </div>
                </div>
                <div className="flex flex-col mt-8">
                  <Calendar />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </div>
  );
}
